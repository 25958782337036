import React from 'react';

export default class Welcome extends React.Component {

    render = () => {
        return (
            <footer style={{...styles.welcome, position:(this.props.relative) ? 'relative' : 'absolute'}}>
                <div style={styles.container} >
                    <span style={styles.text}>
                        <p>Welcome to the Proa Map.</p>
                        <p>If you have a token identifier and map ID, you can access your forecasts by:</p>
                        <p>map.proaanalytics.com/:token/:mapID</p>
                        <p>For more information, please <a style={styles.link} href="https://proaanalytics.com/contact/">contact us</a>.</p>
                    </span>
                </div>
            </footer>
        )
    }
}

const styles = {
    welcome: {
        bottom: 100,
        left: 100,
        width: 500,
        height: 200,
        backgroundColor: "#329ae9",
        display:'flex',
        justifyContent:'center',
        alignItems: 'center',
        color:"#FFF"
    },
    container: {
        width: 'auto',
        height: 'auto',
        marginLeft: 20,
        marginBottom: 20,
        cornerRadius: 15,
        maxWidth: 680
    },
    text: {
        height: '100%',
        fontSize: 18,
        marginLeft: 20,
        color:"#FFF",
    },
    link: {
        color:"#FFF",
        textDecoration: "underline"
    }
}