const asyncForEach = async (array, cb) => {
    for (let index = 0; index < array.length; index++) {
        await cb(array[index], index, array)
    }
}


const createDataset = (times, data) => {
    if (data && data.length > 0){
        return data.map((item,index) => {
            return {x: new Date(times[index] * 1000), y: item}
        });
    } else {
        return null
    }    
}

const getSlice = (data, index) => {
    if (data) {
        return data.slice(index)
    } else {
            return null
    }
}

const isUndefined = (data, index) => {
    return data.every(function(i) { return i === null; });
}


function arrContains(target, pattern){
    var value = 0;
    pattern.forEach(function(word){
      value = value + target.includes(word);
    });
    return (value === 1)
}


export { asyncForEach, createDataset, getSlice, arrContains}