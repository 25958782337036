import React, { Component } from 'react';
import Preloader from '../Preloader'
import Video from '../Video'
import { Panel, Table } from 'react-bootstrap'
//import moment from 'moment-timezone'
export default class ScatterGraph extends Component {

    createTooltip(v) {
        return v
    }


    render() {
        const { summary, metadata } = this.props;
        var isArrImage = Object.prototype.toString.call(metadata.image) == '[object Array]'
        var isArrLapse = Object.prototype.toString.call(metadata.lapse) == '[object Array]'
        if (!isArrImage) {metadata.image = [metadata.image]}
        if (!isArrLapse) {metadata.lapse = [metadata.lapse]}

        if (summary) {

            //const { LastObservationUTC, min1, min10, min30 } = summary;
            return (
                <Panel>
                    <Panel.Body>
                        <div style={Style.somePageWrapper}>
                            <div style={Style.row}>
                                <div style={Style.column}>
                                    <div>
                                        <Table style={{maxWidth: '100%', marginRight: 'auto', marginLeft: 'auto'}} responsive>
                                            <tbody>
                                                {summary.map((item,index) => <tr key={index}><th key={(index + 1) *100}>{item.name}</th><td key={(index + 1) *1000}>{item.description}</td></tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                <div style={Style.column}>
                                { metadata.image? <Video metadata={metadata}/>:null }      
                                </div>

                            </div>
                        </div>
                        
                    </Panel.Body>
                </Panel>
            );
        } else if (summary) {
            return <React.Fragment/>
        } else {
            return <Preloader/>
        }
    }
}


const Style = {
    img: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
        maxWidth: "300px"
    },
    somePageWrapper: {
        display: "flex",
        margin: "15px",
      },
    row: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
      },
      
    column: {
        display: "flex",
        flexDirection: "column",
        flexBasis: "100%",
        flex: 1
    }
};
