import React, { Component } from 'react';
import {Line} from 'react-chartjs-2';
import './Scatter.css';
import Preloader from '../Preloader'
import { Panel } from 'react-bootstrap'
import { createDataset } from '../../lib/util';
 
export default class ScatterGraph extends Component {
    getDatasets() {
        const { actual, forecast_prev, data, times } = this.props;
        return {
            labels: times.map(x => x * 1000),
            datasets: [
            {
                fill: false,
                lineTension: 0.3,
                borderColor: 'rgba(0, 63, 92, 1)',
                backgroundColor: 'rgba(0, 63, 92, 1)',
                borderWidth: 2,
                pointRadius: 0,
                pointHitRadius: 10,
                data: createDataset(actual.time_stamp, actual.wind.ws_m_s), //getFilteredData(times, data, 'gen_p10_W_m2'),
                showLine: true,
                label: "Speed",
                yAxisID: 'speed'
            },
            {
                fill: false,
                lineTension: 0.3,
                    borderColor: 'rgba(0, 63, 92, 0.8)',
                    backgroundColor: 'rgba(0, 63, 92, 0.8)',
                borderWidth: 2,
                pointRadius: 0,
                pointHitRadius: 10,
                data: createDataset(forecast_prev[0].time_stamp, forecast_prev[0].wind.ws_m_s),
                showLine: true,
                label: forecast_prev[0].name + " Speed",
                yAxisID: 'speed'
            },        
            {
                fill: false,
                lineTension: 0.3,
                borderColor: 'rgba(0, 63, 92, 0.6)',
                backgroundColor: 'rgba(0, 63, 92, 0.6)',
                borderWidth: 2,
                pointRadius: 0,
                pointHitRadius: 10,
                data: createDataset(forecast_prev[1].time_stamp, forecast_prev[1].wind.ws_m_s),
                showLine: true,
                label: forecast_prev[1].name + " Speed",
                yAxisID: 'speed'
            },
            {
                fill: false,
                lineTension: 0.3,
                borderColor: 'rgba(0, 63, 92, 0.4)',
                backgroundColor: 'rgba(0, 63, 92, 0.4)',
                borderWidth: 2,
                pointRadius: 0,
                pointHitRadius: 10,
                data: createDataset(forecast_prev[2].time_stamp, forecast_prev[2].wind.ws_m_s),
                showLine: true,
                label: forecast_prev[2].name + " Speed",
                yAxisID: 'speed'
            },
            {
                fill: false,
                lineTension: 0.3,
                borderColor: 'rgba(0, 63, 92, 0.2)',
                backgroundColor: 'rgba(0, 63, 92, 0.2)',
                borderWidth: 2,
                pointRadius: 0,
                pointHitRadius: 10,
                data: createDataset(forecast_prev[3].time_stamp, forecast_prev[3].wind.ws_m_s),
                showLine: true,
                label: forecast_prev[3].name + " Speed",
                yAxisID: 'speed'
            },
            {
                fill: false,
                lineTension: 0.3,
                borderColor: 'rgba(168,221,181, 1)',
                backgroundColor: 'rgba(168,221,181, 1)',
                borderWidth: 2,
                pointRadius: 0,
                pointHitRadius: 10,
                data: createDataset(actual.time_stamp, actual.wind.wd_deg),
                showLine: true,
                label: "Direction",
                yAxisID: 'dir'
            },
            {
                fill: false,
                lineTension: 0.3,
                borderColor: 'rgba(168,221,181, 0.8)',
                backgroundColor: 'rgba(168,221,181, 0.8)',
                borderWidth: 2,
                pointRadius: 0,
                pointHitRadius: 10,
                data: createDataset(forecast_prev[0].time_stamp, forecast_prev[0].wind.wd_deg),
                showLine: true,
                label: forecast_prev[0].name + " Direction",
                yAxisID: 'dir'
            },
            {
                fill: false,
                lineTension: 0.3,
                borderColor: 'rgba(168,221,181, 0.6)',
                backgroundColor: 'rgba(168,221,181, 0.6)',
                borderWidth: 2,
                pointRadius: 0,
                pointHitRadius: 10,
                data: createDataset(forecast_prev[1].time_stamp, forecast_prev[1].wind.wd_deg),
                showLine: true,
                label: forecast_prev[1].name + " Direction",
                yAxisID: 'dir'
            },
 
            {
                fill: false,
                lineTension: 0.3,
                borderColor: 'rgba(168,221,181, 0.4)',
                backgroundColor: 'rgba(168,221,181, 0.4)',
                borderWidth: 2,
                pointRadius: 0,
                pointHitRadius: 10,
                data: createDataset(forecast_prev[2].time_stamp, forecast_prev[2].wind.wd_deg),
                showLine: true,
                label: forecast_prev[2].name + " Direction",
                yAxisID: 'dir'
            },
 
            {
                fill: false,
                lineTension: 0.3,
                borderColor: 'rgba(168,221,181, 0.2)',
                backgroundColor: 'rgba(168,221,181, 0.2)',
                borderWidth: 2,
                pointRadius: 0,
                pointHitRadius: 10,
                data: createDataset(forecast_prev[3].time_stamp, forecast_prev[3].wind.wd_deg),
                showLine: true,
                label: forecast_prev[3].name + " Direction",
                yAxisID: 'dir'
            },
            {
                fill: false,
                lineTension: 0.3,
                borderColor: 'rgba(0, 63, 92, 1)',
                backgroundColor: 'rgba(0, 63, 92, 1)',
                borderWidth: 2,
                pointRadius: 0,
                pointHitRadius: 10,
                data: data.ws_m_s, //getFilteredData(times, data, 'gen_p10_W_m2'),
                showLine: true,
                label: "Speed",
                yAxisID: 'speed'
            },
            {
                fill: false,
                lineTension: 0.3,
                borderColor: 'rgba(168,221,181, 1)',
                backgroundColor: 'rgba(168,221,181, 1)',
                borderWidth: 2,
                pointRadius: 0,
                pointHitRadius: 10,
                data: data.wd_deg, //getFilteredData(times, data, 'gen_p10_W_m2'),
                showLine: true,
                label: "Direction",
                yAxisID: 'dir'
                 
            }]
        }
    }
 
    render() {
        const { title, data } = this.props;
        if (data) {
            const GraphDatasets = getGraphOptions();
 
            return (
                <div>
                   <Panel>
                        <Panel.Heading>
                            <Panel.Title componentClass="h2">{title}</Panel.Title>
                        </Panel.Heading>
                        <Panel.Body>
                            <div id="mini-graph" style={{height: '400px'}}>
                            <Line data={this.getDatasets.bind(this)} options={GraphDatasets} datasetKeyProvider={()=>Math.random()}/>
                            </div>
                        </Panel.Body>
                    </Panel>
                </div>
            );
        } else {
            return <Preloader/>
        }
    }
}
 
const getGraphOptions = () => ({
    maintainAspectRatio: false,
    tooltips: {
        mode: 'label'
      },
    scales: {
         
        xAxes: [{
            type: 'time',
            time: {
                unit: 'hour',
                displayFormats: { hour: 'MMM DD, HH:mm' },
                tooltipFormat: 'MMM DD, HH:mm'
            },
            display: true,
            ticks: { fontSize: 12 }
        }],
        yAxes: [{
            id: 'speed',
            position: 'left',
            ticks: {
                fontSize: 12,
                suggestedMin: 0
            },
            scaleLabel: {
                display: true,
                labelString: 'Speed (m/s)'
            }
        },{
            id: 'dir',
            position: 'right',
            ticks: {
                fontSize: 12,
                min: -180,
                max: 180,
                stepSize: 90
            },
            scaleLabel: {
                display: true,
                labelString: 'Direction (°)'
            }
        } 
    ]
    },
    legend: {
        display: true,
        labels: {
             filter: function(legendItem, data) {
                if(legendItem.text === "hidden"){
                    return false
                }
                else {
                    return true;
                }
             },
        }
     
         
   }
})