import React, { Component } from 'react';
import './Popup.css'
import { Panel } from 'react-bootstrap'
import Preloader from './Preloader'
import CurrentStatusPopup from '../components/LocationLayout/CurrentStatusPopup';
import GraphGenerationPopup from '../components/LocationLayout/GraphGenerationPopup';
import { Link } from 'react-router-dom';

import GraphIrradiancePopup from '../components/LocationLayout/GraphIrradiancePopup';

export default class Popup extends Component {

    popup(location) {
        const data = location.location
        // console.log(this.props)
        return (
            <div>
                <Panel>
                        <CurrentStatusPopup summary={data.table} metadata={data.metadata}/>
                        { data.forecast.generation? <GraphGenerationPopup title="Generation" times={data.actual.time_stamp} generation={data.actual.generation.gen_W} forecast={data.forecast.generation} idx={data.actual.now_index} />:null}
                        { data.forecast.irradiance? <GraphIrradiancePopup title="Irradiance" data={data.forecast.irradiance} times={data.forecast.time_stamp} />:null}
                    <Panel.Footer>
                        { data.metadata.display_full? <Link to={'/location/' + data.metadata.display_full.token + '/' + data.metadata.display_full.product_id}>View Forecast</Link>:null}
                    </Panel.Footer>
                    <Panel.Footer>
                        { data.metadata.display_full? <Link to={'/locationhistory/' + data.metadata.display_full.token + '/' + data.metadata.display_full.product_id}>View History</Link>:null}
                    </Panel.Footer>
                </Panel>
            </div>
        )
    }

    render() {
        console.log(this.props)
        const { location } = this.props;
        if (location && location.location) {
            return this.popup(location)
        } else {
            return (
                <Panel>
                    <Panel.Heading/>
                    <Preloader/>
                    <Panel.Footer/>
                </Panel>
            )
        }
    }
}