import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Layout from './container/Layout';
import LocationLayout from './container/LocationLayout';
import LocationHistoryLayout from './container/LocationHistoryLayout';


import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import About from './components/About';
import _404 from './container/404';




import IEBrowserDetected from './components/IEBrowserDetected';
import { detect } from 'detect-browser';
import { createBrowserHistory } from "history";




const browser = detect();



const NonIEBrowserRoute = ({ component: Component, ..._props }) => {
    return (
        <Route {..._props} render={(props) => (
            (browser.name !== 'ie')
                ? <Component {..._props} />
                : <IEBrowserDetected />
        )} />
    )
}

export default class Routes extends Component {
    render() {
        return (
            <Router >
                    <Switch>
                        <NonIEBrowserRoute exact path="/:token/:product" component={Layout} />
                        <Route path="/location/:token/:product" component={LocationLayout} />
                        <Route path="/locationhistory/:token/:product" component={LocationHistoryLayout} />
                        <Route path="/privacypolicy" component={PrivacyPolicy} />
                        <Route path="/termsofservice" component={TermsOfService} />
                        <Route path="/about" component={About} />
                        <Route path="/" component={Layout} />
                        <Route component={_404} />
                    </Switch>
            </Router>
        );
    }
}