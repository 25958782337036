import React, { Component } from 'react';
import {Line} from 'react-chartjs-2';
import Preloader from '../Preloader'
import { Panel } from 'react-bootstrap'
import Numeral from "numeral";
import { createDataset } from '../../lib/util';
 
export default class ScatterGraph extends Component {
    getDatasets() {
        const { actual, forecast_prev, forecast } = this.props;

        const lastNonNull = actual.generation.gen_W.filter(x => x).pop();
        const indexOfLastNonNull = actual.generation.gen_W.indexOf(lastNonNull);

        // The arrays are padded so they can be easily plotted on the same axis on the LocationLayout.
        // Here only want to plot the past values of actual/forecast_prev, hence take slices of all the arrays.
        const datasets = 
        {datasets: [{
            fill: false,
            lineTension: 0.3,
            borderColor: '#505050',
            backgroundColor: '#505050',
            borderWidth: 2,
            pointRadius: 0,
            pointHitRadius: 10,
            data: createDataset(actual.time_stamp.slice(0, actual.now_index), actual.generation.gen_W.slice(0, actual.now_index)),
            showLine: true,
            label: "actual"
            // hidden: !actual.generation.gen_W
        }
        ,
        {
            fill: false,
            lineTension: 0.3,
            borderColor: 'rgba(255, 124, 67, 1)',
            backgroundColor: 'rgba(255, 124, 67, 1)',
            borderWidth: 2,
            pointRadius: 0,
            pointHitRadius: 10,
            data: createDataset(forecast_prev[0].time_stamp.slice(0, actual.now_index), forecast_prev[0].generation.gen_W.slice(0, actual.now_index)),
            showLine: true,
            label: forecast_prev[0].name
            // hidden: !forecast_prev[0].generation.gen_W
        },
        {
            fill: false,
            lineTension: 0.3,
            borderColor: 'rgba(255, 124, 67, 0.75)',
            backgroundColor: 'rgba(255, 124, 67, 0.75)',
            borderWidth: 2,
            pointRadius: 0,
            pointHitRadius: 10,
            data: createDataset(forecast_prev[1].time_stamp.slice(0, actual.now_index), forecast_prev[1].generation.gen_W.slice(0, actual.now_index)),
            showLine: true,
            label: forecast_prev[1].name
            // hidden: !forecast_prev[1].generation.gen_W
        },
        {
            fill: false,
            lineTension: 0.3,
            borderColor: 'rgba(255, 124, 67, 0.5)',
            backgroundColor: 'rgba(255, 124, 67, 0.5)',
            borderWidth: 2,
            pointRadius: 0,
            pointHitRadius: 10,
            data: createDataset(forecast_prev[2].time_stamp.slice(0, actual.now_index), forecast_prev[2].generation.gen_W.slice(0, actual.now_index)),
            showLine: true,
            label: forecast_prev[2].name
            // hidden: !forecast_prev[2].generation.gen_W
        },
        {
            fill: false,
            lineTension: 0.3,
            borderColor: 'rgba(255, 124, 67, 0.25)',
            backgroundColor: 'rgba(255, 124, 67, 0.25)',
            borderWidth: 2,
            pointRadius: 0,
            pointHitRadius: 10,
            data: createDataset(forecast_prev[3].time_stamp.slice(0, actual.now_index), forecast_prev[3].generation.gen_W.slice(0, actual.now_index)),
            showLine: true,
            label: forecast_prev[3].name
            // hidden: !forecast_prev[3].generation.gen_W
        }
        ]}
     
        return datasets
    }
 
    render() {
        const { title, actual } = this.props;
        if (actual) {
            const GraphDatasets = getGraphOptions(this.props.forecast_prev[0].generation.gen_W.slice(0, actual.now_index));
 
            return (
                <div>
                   <Panel>
                        <Panel.Heading>
                            <Panel.Title componentClass="h2">{title}</Panel.Title>
                        </Panel.Heading>
                        <Panel.Body>
                            <div id="mini-graph" style={{height: '400px'}}>
                            <Line data={this.getDatasets.bind(this)} options={GraphDatasets} datasetKeyProvider={()=>Math.random()}/>
                            </div>
                        </Panel.Body>
                    </Panel>
                </div>
            );
        } else {
            return <Preloader/>
        }
    }
}
 
const getGraphOptions = (vals) => ({
    maintainAspectRatio: false,
    tooltips: {
        mode: 'label',
        callbacks: {
                  label: function (tooltipItems, data) {
                        if (tooltipItems.yLabel > 1e6) {
                              return Numeral(tooltipItems.yLabel/1e6).format('0.00')
                          } else {
                            return Numeral(tooltipItems.yLabel/1e3).format('0.00')
                          }
                      }
          }
      },
    scales: {
        xAxes: [{
            type: 'time',
            time: {
                unit: 'hour',
                displayFormats: { hour: 'MMM DD, HH:mm' },
                tooltipFormat: 'MMM DD, HH:mm'
            },
            display: true,
            ticks: { fontSize: 12 }
        }],
        yAxes: [{
            ticks: {
                fontSize: 12,
                suggestedMin: 0,
                callback: function(value, index, values) {
                    if (Math.max.apply(Math, values) > 1e6) {
                        return Numeral(value/1e6).format('0.0')
                  } else if (Math.max.apply(Math, values) > 1e3) {
                        return Numeral(value/1e3).format('0.0')
                  }
                    else {return Numeral(value).format('0.0')}
                }
            },
            scaleLabel: {
                display: true,
                labelString: getLabel(vals)
            }
        }]
    },
    legend: {
        display: true
        // labels: {
        //     filter: function(item, chart) {
        //         return !item.hidden}
        // }
    }
})

var getLabel = function (values) {
    if (Math.max.apply(Math, values)>1e6) {
        return "Power (MW)"}
    else if (Math.max.apply(Math, values)>1e3) {
        return "Power (kW)"
    }
    else { return "Power (W)"}
}