import React, { Component } from 'react';
import {Line} from 'react-chartjs-2';
import './Scatter.css';
import Preloader from '../Preloader'
import { Panel } from 'react-bootstrap'
import { createDataset } from '../../lib/util';
 
export default class ScatterGraph extends Component {
    getDatasets() {
        const { actual, forecast_prev, data, times } = this.props;
        var len = Object.keys(data.ghi_p50_W_m2).length
        console.log(actual.nw)
        return {
            labels: times.map(x => x * 1000),
            datasets: [
            {
                fill: false,
                lineTension: 0.3,
                borderColor: 'rgba(44, 127, 184, 1)',
                backgroundColor: 'rgba(44, 127, 184, 1)',
                borderWidth: 2,
                pointRadius: 0,
                pointHitRadius: 10,
                data: createDataset(forecast_prev[0].time_stamp.slice(0, actual.now_index), forecast_prev[0].irradiance.ghi_W_m2.slice(0, actual.now_index)),
                showLine: true,
                label: "",
            },
            {
                fill: false,
                lineTension: 0.3,
                borderColor: 'rgba(44, 127, 184, 1)',
                backgroundColor: 'rgba(44, 127, 184, 1)',
                borderWidth: 2,
                pointRadius: 0,
                pointHitRadius: 10,
                data: data.ghi_p50_W_m2, //getFilteredData(times, data, 'gen_p10_W_m2'),
                showLine: true,
                label: "Global Horizontal Irradiance (GHI)"
            },
            {
                fill: false,
                lineTension: 0.3,
                borderColor: 'rgba(44, 127, 184, 0.7)',
                backgroundColor: 'rgba(44, 127, 184, 0.2)',
                borderDash: [10,5],
                borderWidth: 1,
                pointRadius: 0,
                pointHitRadius: 10,
                data: data.ghi_p10_W_m2, //getFilteredData(times, data, 'gen_p10_W_m2'),
                showLine: true,
                label: "10% POE (GHI)"
            },
            {
                fill: 3,
                lineTension: 0.3,
                borderColor: 'rgba(44, 127, 184, 0.7)',
                backgroundColor: 'rgba(44, 127, 184, 0.2)',
                borderDash: [10,5,2,5],
                borderWidth: 1,
                pointRadius: 0,
                pointHitRadius: 10,
                data: data.ghi_p90_W_m2, //getFilteredData(times, data, 'gen_p10_W_m2'),
                showLine: true,
                label: "90% POE (GHI)"
            }
            ]
        }
    }
 
    render() {
        const { title, data } = this.props;
        if (data) {
            const GraphDatasets = getGraphOptions();
 
            return (
                <div>
                   <Panel>
                        <Panel.Heading>
                            <Panel.Title componentClass="h2">{title}</Panel.Title>
                        </Panel.Heading>
                        <Panel.Body>
                            <div id="mini-graph" style={{height: '400px'}}>
                            <Line data={this.getDatasets.bind(this)} options={GraphDatasets} datasetKeyProvider={()=>Math.random()}/>
                            </div>
                        </Panel.Body>
                    </Panel>
                </div>
            );
        } else {
            return <Preloader/>
        }
    }
}
 
const getGraphOptions = () => ({
    maintainAspectRatio: false,
    tooltips: {
        mode: 'label'
      },
    scales: {
        xAxes: [{
            type: 'time',
            time: {
                unit: 'hour',
                displayFormats: { hour: 'MMM DD, HH:mm' },
                tooltipFormat: 'MMM DD, HH:mm'
            },
            display: true,
            ticks: { fontSize: 12 }
        }],
        yAxes: [{
            ticks: {
                fontSize: 12,
                suggestedMin: 0
            },
            scaleLabel: {
                display: true,
                labelString: 'Irradiance (W/m²)'
            }
        }]
    },
    legend: {
        display: true,
        labels: {
             filter: function(legendItem, data) {
                if(legendItem.text === "p10" || legendItem.text === "p90"){
                    return false
                }
                else {
                    return true;
                }
             },
        }
     
         
   }
})