export default function reducer(state = { location: undefined}, action) {
    switch (action.type) {
        case 'ON_SELECT': {
            return {
                ...state,
                location: action.location
            };
        }
        case 'ON_LOCATION': {
            return {
                ...state,
                location: action.location
            };
        }
        case 'ON_RESET': {
            return {
                ...state,
                location: undefined,
            }
        }
        default: {
            return {...state};
        }
    }
}

export const Types = {
    ON_SELECT: 'ON_SELECT',
    ON_STATION_HISTORY: 'ON_LOCATION',
    ON_RESET: 'ON_RESET'
}
