import React, { Component } from 'react';
import {Line} from 'react-chartjs-2';
import Preloader from '../Preloader'
import { Panel } from 'react-bootstrap'
import { createDataset } from '../../lib/util';
 
export default class ScatterGraph extends Component {
    getDatasets() {
        const { actual, forecast_prev } = this.props;
        const datasets = 
        {datasets: [{
            fill: false,
            lineTension: 0.3,
            borderColor: 'rgba(44, 127, 184, 1)',
            backgroundColor: 'rgba(44, 127, 184, 1)',
            borderWidth: 2,
            pointRadius: 0,
            pointHitRadius: 10,
            data: createDataset(forecast_prev[0].time_stamp.slice(0, actual.now_index), forecast_prev[0].irradiance.ghi_W_m2.slice(0, actual.now_index)),
            showLine: true,
            label: "Global Horizontal Irradiance (GHI)",
        }
        ]}
     
        return datasets
    }
 
    render() {
        const { title, actual } = this.props;
        if (actual) {
            const GraphDatasets = getGraphOptions();
 
            return (
                <div>
                   <Panel>
                        <Panel.Heading>
                            <Panel.Title componentClass="h2">{title}</Panel.Title>
                        </Panel.Heading>
                        <Panel.Body>
                            <div id="mini-graph" style={{height: '400px'}}>
                            <Line data={this.getDatasets.bind(this)} options={GraphDatasets} datasetKeyProvider={()=>Math.random()}/>
                            </div>
                        </Panel.Body>
                    </Panel>
                </div>
            );
        } else {
            return <Preloader/>
        }
    }
}
 
const getGraphOptions = () => ({
    maintainAspectRatio: false,
    tooltips: {
        mode: 'label'
      },
    scales: {
        xAxes: [{
            type: 'time',
            time: {
                unit: 'hour',
                displayFormats: { hour: 'MMM DD, HH:mm' },
                tooltipFormat: 'MMM DD, HH:mm'
            },
            display: true,
            ticks: { fontSize: 12 }
        }],
        yAxes: [{
            ticks: {
                fontSize: 12,
                suggestedMin: 0
            },
            scaleLabel: {
                display: true,
                labelString: 'GHI (W/m²)'
            }
        }]
    },
    legend: {
        display: true
    }
})