import React, { Component } from 'react';
import { Image } from 'react-bootstrap'

//--------------------------------------------
export default class ScatterGraph extends Component {
// var Video = React.createClass({
  render () {
    const { metadata } = this.props;
    const isFirefox = typeof InstallTrigger !== 'undefined';
    if (!isFirefox) {
      if (false) {
        return (
          <div style={Style.img}>
            <video
                width="70%" height="70%" display="table-cell" controls autoPlay muted
                src={"https://forecasts.proaanalytics.com/GetImage/" + metadata.lapse[0].token + "/" + metadata.lapse[0].image_id}/>
            {` `}
            <video
                width="70%" height="70%" display="table-cell" controls autoPlay muted
                src={"https://forecasts.proaanalytics.com/GetImage/" + metadata.lapse[1].token + "/" + metadata.lapse[1].image_id}/>
          </div> )
          }
      else if (true) {
        return (
        <div>
          <div style={Style.img}>
            <video
              width="100%" height="100%" display="block" controls autoPlay muted
              src={"https://forecasts.proaanalytics.com/GetImage/" + metadata.lapse[0].token + "/" + metadata.lapse[0].image_id}/>
              </div>
          <div id="link" style={Style.img}><a href={"https://forecasts.proaanalytics.com/GetImage/" + metadata.lapse[0].token + "/" + metadata.lapse[0].image_id}>Download skycam timelapse</a></div>
          </div>) }
      else { return(
                null    )
            }
      }
    else {
      if (false)
        return (
          <div style={Style.img}>
            <img width="45%" height="45%" src={"https://forecasts.proaanalytics.com/GetImage/" + metadata.image[0].token + "/" + metadata.image[0].image_id} alt="skycam image" />
            {` `}
            <img width="45%" height="45%" src={"https://forecasts.proaanalytics.com/GetImage/" + metadata.image[1].token + "/" + metadata.image[1].image_id} alt="skycam image" />
          </div>
        )
      else if (true) {
        return (
        <div>
          <Image style={Style.img} src={"https://forecasts.proaanalytics.com/GetImage/" + metadata.image[0].token + "/" + metadata.image[0].image_id} alt="skycam image"/>
        <div id="link" style={Style.img}><a href={"https://forecasts.proaanalytics.com/GetImage/" + metadata.lapse[0].token + "/" + metadata.lapse[0].image_id}>Download skycam timelapse</a></div>
        </div> )}
      else { return(
              null)
            }
    }
  }
}

const Style = {
    img: {
        display: "flex",
        flexDirection: "row",
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
        maxWidth: "600px"
    }
  }
