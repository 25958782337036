export default function reducer(state = { event: undefined, urls: undefined, urlsTimestamp: 0 }, action) {
    switch (action.type) {
        case Types.ON_SET_URLS: {
            return {
                ...state,
                urls: action.urls,
                urlsTimestamp: action.urlsTimestamp
            }
        }
        default: {
            return {...state};
        }
    }
}

export const Types = {
    ON_SET_URLS: 'ON_SET_URLS'
}
