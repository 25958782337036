import React, { Component } from 'react';
import GraphGeneration from '../components/LocationHistoryLayout/GraphGeneration';
import GraphIrradiance from '../components/LocationHistoryLayout/GraphIrradiance';
import GraphTempHum from '../components/LocationHistoryLayout/GraphTempHum';
import GraphWind from '../components/LocationHistoryLayout/GraphWind';
import GraphRain from '../components/LocationHistoryLayout/GraphRain';
import CurrentStatus from '../components/LocationLayout/CurrentStatus';
import Preloader from '../components/Preloader';
import './LocationLayout.css';
import '../index.css';
import { Link } from 'react-router-dom';
import { arrContains } from '../lib/util';
import Footer from '../components/Footer';
import Header from '../components/HeaderInternal';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { getLocationHistoryData, getForecastData } from '../lib/api'
import { getValidUserData } from '../lib/api'
import Login from './login/Login';
import { getUser, getPassword, removeUserSession } from './login/common';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as CSV from 'csv-string';

export default class LocationLayout extends Component {
    state = { data: undefined }

    componentDidMount = async () => {
        const user = getUser();
        await this.login();
        if (this.state.valid == "private") {
            if (user) {
                
                await this.load();
                await this.forecast();
                const interval = setInterval(this.load.bind(this), 60000);
                this.setState({ interval });
                //enableBodyScroll(this.targetElement);
                //enableBodyScroll(document.getElementById('station-page'));
                clearAllBodyScrollLocks();
            }
        }
        else {
            await this.load();
            await this.forecast();
            const interval = setInterval(this.load.bind(this), 60000);
            this.setState({ interval });
            //enableBodyScroll(this.targetElement);
            //enableBodyScroll(document.getElementById('station-page'));
            clearAllBodyScrollLocks();
        }
    }

    componentWillUnmount = () => {
        clearInterval(this.state.interval);
    }

    load = async () => {
        const { product, token } = this.props.match.params;
        this.setState({ data: await getLocationHistoryData(product, token) });
    }

    login = async () => {
        let { token, product } = this.props.match.params;
        this.setState({ token: token });
        this.setState({ product: product });
        let result = await getValidUserData(product, token)
        if (result.Message == "private") {
            this.setState({ valid: "private" });
        } else {

            this.setState({ valid: "public" })

        }
    }
    forecast = async () => {
        let { token, product } = this.props.match.params;
        let resultforecast = await getForecastData(product, token)
        let res = await fetch("https://forecasts.proaanalytics.com/GetForecast/" + token + "/" + product, {
        //let res = await fetch("/GetForecast/" + token + "/" + product, {
            headers: {
                "Content-Type": "application/json",
                "Username": getUser(),
                "Password": getPassword()
            }
        })
        let file = res.headers.get("content-disposition").split(';')[1];
        let forecastfile = file.split('=')[1];

        if (forecastfile) {

            this.setState({ forecastdata: resultforecast });
            this.setState({ filename: forecastfile });
        }
        else
            this.setState({ forecastdata: "Requested data is not available" });

    }

    render() {
        const { data } = this.state;
        const { valid } = this.state;
        const user = getUser();
        let { token, product } = this.state;

        if (user) {
            if (user == "proadmin") {
                this.props.match.params.override = true
            }
            return (
                <div>
                    <Header params={this.props.match.params} />
                    {
                        (data)
                            ? <Display data={this.state.data} params={this.props.match.params} dataforecast={this.state.forecastdata} filename={this.state.filename} />
                            : <Preloader style={{ marginTop: 300 }} />
                    }
                </div>
            );
        }
        else {
            if (valid == "private") {
                return (
                    <div>
                        <Header params={this.props.match.params} />
                        <Login token={token} product={product} />
                        <Footer />
                    </div>
                )
            }
            else {
                return (
                    <div>
                        <Header params={this.props.match.params} />
                        {
                            (data)
                                ? <Display data={this.state.data} params={this.props.match.params} dataforecast={this.state.forecastdata} filename={this.state.filename} />
                                : <Preloader style={{ marginTop: 300 }} />
                        }
                    </div>
                );
            }
        }
    }
}

const Display = ({ data, params, dataforecast, filename }) => {
    var do_not_show_fc_download = ['mondo', 'collinsville'];
    var fileDownload = require('js-file-download');
    var forecastdownload = JSON.stringify(dataforecast);

    if (filename) {
        if (filename.split('.')[1] == 'csv')
            var forecastdownload = dataforecast;
        else
            var forecastdownload = JSON.stringify(dataforecast);


        //const { metadata } = this.props;
    }

    if (data.metadata) {
        return (
            <React.Fragment>
                <div id="station-page" >
                    <CurrentStatus summary={data.table} metadata={data.metadata} />
                    <div class="container">
                        <div class="floatlink">
                            <Link to={'/location/' + params.token + '/' + params.product}>View forecast data</Link>
                        </div>
                        {data.metadata.report ? <div class="floatlink">
                            <a href={"https://forecasts.proaanalytics.com/GetReport/" + params.token + "/" + params.product} target="_new">View PDF report</a>
                        </div> : null}
                        {/* <div class="floatlink">
        { arrContains(params.product,do_not_show_fc_download)==false? <a href={"https://forecasts.proaanalytics.com/Getforecast/" + params.token + "/" + params.product}>Download forecast</a>:null }
        </div> */}
                        <div class="floatlink">
                            <a style={{ cursor: 'pointer' }} onClick={() => fileDownload(forecastdownload, filename)}>Download forecast</a>
                        </div>
                    </div>
                    {data.actual.generation ? <GraphGeneration title="Historic Generation" actual={data.actual} forecast_prev={data.forecast_prev} forecast={data.forecast} /> : null}
                    {data.actual.irradiance ? <GraphIrradiance title="Historic Iradiance" actual={data.actual} forecast_prev={data.forecast_prev} /> : null}
                    {data.actual.temphum ? <GraphTempHum title="Historic Temperature / Humidity" actual={data.actual} forecast_prev={data.forecast_prev} /> : null}
                    {data.actual.wind ? <GraphWind title="Historic Wind" actual={data.actual} forecast_prev={data.forecast_prev} /> : null}
                    {data.forecast_prev[0].precipitation ? <GraphRain title="Historic Precipitation" actual={data.actual} forecast_prev={data.forecast_prev} /> : null}
                </div>
                <Footer relative={true} />
            </React.Fragment>
        )
    }

}

