import React, { Component } from 'react';
import { loadMap, loadFeatures, registerEvent, calculateCentre } from '../lib/maps';
import { selectLocation, reset, dispatchUrls } from '../actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import Popup from '../components/Popup';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Welcome from '../components/Welcome';
import './Layout.css';
import '../index.css';
import { getValidUserData } from '../lib/api'
import Login from './login/Login';
import { getUser, getPassword, removeUserSession } from './login/common';


const mapId = 'map', popupId = 'popup';


class Layout extends Component {
    state = {
        username: "",
        password: ""
    }


    componentDidMount = async () => {
        let user = getUser()

        if (this.props.path == "/:token/:product") {
            await this.login();
        }
        if (this.state.valid == "private") {
            if (user) {
                
                if (this.props.match) {
                    this.zoom = [140, -30, 4.8]
                } else {

                    // let this.displayName
                    [this.zoom, this.displayName] = await calculateCentre(this.props)
                }
                this.map = loadMap(mapId, this.props, this.zoom);

                registerEvent(this.map,
                    this.props.selectLocation.bind(this),
                    this.props.reset.bind(this),
                    popupId);

                // init load
                await load(this.map, this.props);

                // reloads features every minute
                this.setState({ interval: setInterval(this.poll.bind(this), 60000) });
            }
        }
        else {
            if (this.props.match) {
                this.zoom = [140, -30, 4.8]
            } else {

                // let this.displayName
                [this.zoom, this.displayName] = await calculateCentre(this.props)
            }
            this.map = loadMap(mapId, this.props, this.zoom);

            registerEvent(this.map,
                this.props.selectLocation.bind(this),
                this.props.reset.bind(this),
                popupId);

            // init load
            await load(this.map, this.props);

            // reloads features every minute
            this.setState({ interval: setInterval(this.poll.bind(this), 60000) });
        }
    }

    componentWillUnmount = () => { clearInterval(this.state.interval); }

    poll = async () => {
        await load(this.map, this.props)
    }

    onCloseDetails = () => { this.setState({ openDetails: false }) }

    login = async () => {
        let { token, product } = this.props.computedMatch.params;
        this.setState({ token: token });
        this.setState({ product: product });
        let result = await getValidUserData(product, token)
        if (result.Message == "private") {
            this.setState({ valid: "private" });
            this.setState({ isAuthenticated: false });

        } else {

            this.setState({ valid: "public" })

        }

    }


    render = () => {
        const { location } = this.props

        let { token, product, valid, username } = this.state;

        const pwd = getPassword();
        const user = getUser();


        if (this.props.path == "/:token/:product") {

            if (user) 
            {
                return (
                    <div>
                        <Header displayName={this.displayName} />
                        <div style={{ height: '100vh', width: '100%', zIndex: 0 }} id={mapId} />
                        <div id={popupId} className="ol-popup" onMouseUp={convertToClick}>
                            <Popup location={location} />
                        </div>
                        <Footer />
                    </div>

                );
            }
            else {
                if (valid == "private") {
                    return (

                        <div>
                            <Header />
                            <Login token={token} product={product} />
                            <Footer />
                        </div>)


                }
                else {
                    return (
                        <div>
                            <Header displayName={this.displayName} />
                            <div style={{ height: '100vh', width: '100%', zIndex: 0 }} id={mapId} />
                            <div id={popupId} className="ol-popup" onMouseUp={convertToClick}>
                                <Popup location={location} />
                            </div>
                            <Footer />
                        </div>

                    );
                }
            }
        }


        else {
            return (
                <div>
                    <Header />
                    <div style={{ height: '100vh', width: '100%', zIndex: 0 }} id={mapId} />
                    <div id={popupId} className="ol-popup" onMouseUp={convertToClick}>
                        <Popup location={location} />
                    </div>
                    <Welcome />
                    <Footer />
                </div>
            );

        }
    }
}

const load = async (map, props) => {
    loadFeatures(map, props)
}

// fix to enable firing of onClick events on openlayers popups
const convertToClick = (e) => {
    const evt = new MouseEvent('click', { bubbles: true })
    evt.stopPropagation = () => { }
    e.target.dispatchEvent(evt)
}

const mapStateToProps = (state) => {
    return {
        location: state.location,
        urls: state.feature.urls,
        urlsTimestamp: state.feature.urlsTimestamp,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        selectLocation: bindActionCreators(selectLocation, dispatch),
        reset: bindActionCreators(reset, dispatch),
        dispatchUrls: bindActionCreators(dispatchUrls, dispatch)
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Layout);
