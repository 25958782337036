
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { setUserSession } from './common';
import { getSignInData } from '../../lib/api'

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import RaisedButton from 'material-ui/RaisedButton';

import {useSelector, useDispatch} from 'react-redux'


function Login(props) {
  const [loading, setLoading] = useState(false);
  const username = useFormInput('');
  const password = useFormInput('');
  const [error, setError] = useState(null);
  const history = useHistory();
  let token = props.token;
  let product = props.product;
  const dispatch = useDispatch()


  // handle button click of login form
  const handleLogin = async (props) => {
    setError(null);
    setLoading(true);
    await SignIn();

  }

  const SignIn = async (props) => {
    console.log(username)
    console.log(token)
    let response = await getSignInData(username.value, password.value, token);
    if (response.Message == "Authorised") {
      setLoading(false);
      setUserSession(token, username.value,password.value)
      
      
      let datapath = "/" + token + "/" + product

      //Redirect to the desired location

      //let authData = {username: username.value, password: password.value};
      //dispatch(authUser(authData));

      history.go(datapath)
        //appState:{username:username.value, password:password.value}}); 

    }
    else {
      if (response.Message == "Incorrect username or password.") {
        setLoading(false);
        setError(response.Message);
      }
      else
        setError("Something went wrong... Please reload or try again later.");
    }

  }
  
 
  return (
    <MuiThemeProvider>
      <div>

        <br />
        <br />
        
        <div style={{position: 'absolute', marginTop: 100, left: '40%'}}>
          Username<br />
          <input type="text" {...username} autoComplete="new-password" size="30"  />
        </div >
        <div style={{position: 'relative', marginTop: 150 ,left: '40%'}}>
          Password<br />
          <input type="password" {...password} autoComplete="new-password" size="30"  />
        </div>        {error && <><small style={{ position: 'relative',color: 'red' ,left: '40%'}}>{error}</small><br /></>}<br />
        <RaisedButton style={{position: 'relative' ,left: '45%'}} label="Login" value={loading ? 'Loading...' : 'Login'} onClick={handleLogin} disabled={loading} size="30"/><br />

      </div>
    </MuiThemeProvider>

  );
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}





export default Login;
