import React, { Component } from 'react';

export default class TermsOfService extends Component {
    render() {
        return (
         <div style={{padding: '40px'}}>
            <h1>Terms of Service ("Terms")</h1>
            <p>The information displayed on the Proa Analytics Solar Forecast Interactive Map ("Proa Map") is for general informational purposes only, and is not intended to provide any commercial, financial, or legal advice. Any information in connection with  may not be appropriate to your individual needs. You must exercise your own independent, skill, care and judgment with respect to how you use the information displayed on the Proa Map. In any important matter, you should seek professional advice relevant to your own circumstances.</p>

<p>Proa Analytics makes no representations or warranties regarding the accuracy or completeness of any content or the product in connection with the Proa Map. Proa Analytics disclaims all responsibility and all liability (including without limitation, liability in negligence, for errors or omissions) for all expenses, loss, damage and costs which you might incur as a result of the information displayed on the Proa Map and your use of it.</p>

<p>The Proa Map must not be used for navigation or precise spatial analysis.</p>

<p>Access to data:</p>

<p>Any information provided by data custodians displayed on the Proa Map is provided as is and on the understanding that the respective data custodian is not responsible for, nor guarantees the timeliness, accuracy or completeness of that information. If you intend to rely on any information displayed on the Proa Map, then you must apply in writing to the respective data custodian for further authorisation.</p>

<p>You acknowledge that your use of the Proa Map means that you have provided your acceptance of these terms and conditions.</p>

<p>You must consider the usage rights and restrictions of the basemap and each activated dataset when using the output of the Proa Map in broadcast, print, or other distribution. Open Street Map's terms of use are available: https://wiki.osmfoundation.org/wiki/Terms_of_Use</p>
         </div>
        );
    }
}
