import axios from 'axios';
import { getUser, getPassword } from './.././container/login/common'


const getLocationData = async (Name, token) => {
    let urls = "https://forecasts.proaanalytics.com/DispData/"
    //let urls = "/DispData/"
    urls = urls + token + '/' + Name
    const path = `${urls}`;
    let requestData = await get(path, false)
    return requestData;
}

const getLocationHistoryData = async (Name, token) => {
    let urls = "https://forecasts.proaanalytics.com/DispData/"
    //let urls = "/DispData/"
    urls = urls + token + '/' + Name
    const path = `${urls}`;
    let requestData = await get(path, false)
    return requestData;
}

const getPopupData = async (Name, token) => {
    let urls = "https://forecasts.proaanalytics.com/DispDataMini/"
    //let urls = "/DispDataMini/"
    urls = urls + token + '/' + Name
    const path = `${urls}`;
    let requestData = await get(path, false)
    return requestData;
}


const getValidUserData = async (Name, token) => {
    let urls = "https://forecasts.proaanalytics.com/AuthUser/"
    //let urls = "/AuthUser/"
    urls = urls + token + '/' + Name

    const path = `${urls}`;
    let requestData = await get(path, false)
    return requestData;
}

const getSignInData = async (username, passord,token) => {
    let urls = "https://forecasts.proaanalytics.com/SignIn/"
    //let urls = "/SignIn/"
    urls = urls + username + '/' + passord+ '/' + token

    const path = `${urls}`;
    let requestData = await get(path, false)
    return requestData;
}


const getForecastData = async (Name,token) => {
    let urls = "https://forecasts.proaanalytics.com/GetForecast/"
    //let urls = "/GetForecast/"
    urls = urls + token + '/' + Name

    const path = `${urls}`;
    let requestData = await get(path, false)
    return requestData;
}

const get = async (path, isAuth) => {
    return await call(
        await createRequest('GET', path, isAuth)
    );
}
const createRequest = async (method, path, isAuth, data) => {
    let param = {
        method,
        url: path,
        headers: {
            "Content-Type": "application/json",
            "Username": getUser(),
            "Password": getPassword()
        }
    }
    if (data) { param.data = JSON.stringify(data) }
    return param;
}

const getFeatures = async url => {
    return await call(url);
}

const call = async (params) => {
    try {
        const response = await axios(params);
        return response.data;
    } catch (e) {
        return { status: 500, message: JSON.stringify(e)};
    }
}

export { getLocationData, getLocationHistoryData, getFeatures, getPopupData, getValidUserData, getSignInData, getForecastData};
