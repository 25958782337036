import React, { Component } from 'react';
import GraphGeneration from '../components/LocationLayout/GraphGeneration';
import GraphIrradiance from '../components/LocationLayout/GraphIrradiance';
import GraphTempHum from '../components/LocationLayout/GraphTempHum';
import GraphWind from '../components/LocationLayout/GraphWind';
import GraphRain from '../components/LocationLayout/GraphRain';
import CurrentStatus from '../components/LocationLayout/CurrentStatus';
import Preloader from '../components/Preloader';
import './LocationLayout.css';
import '../index.css';
import { Link } from 'react-router-dom';
import { arrContains } from '../lib/util';

import Footer from '../components/Footer';
import Header from '../components/HeaderInternal';
import * as CSV from 'csv-string';



import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { getLocationData } from '../lib/api'
import { getValidUserData, getForecastData } from '../lib/api'
import Login from './login/Login';
import { getUser, getPassword, removeUserSession } from './login/common';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class LocationLayout extends Component {
    state = { data: undefined }

    componentDidMount = async () => {
        const user = getUser();
        await this.login();
        if (this.state.valid == "private") {
            if (user) {
                await this.load();
                await this.forecast();
                const interval = setInterval(this.load.bind(this), 60000);
                this.setState({ interval });
                clearAllBodyScrollLocks();
            }
        }
        else {
            await this.load();
            await this.forecast();
            const interval = setInterval(this.load.bind(this), 60000);
            this.setState({ interval });
            clearAllBodyScrollLocks();
        }
    }

    componentWillUnmount = () => {
        clearInterval(this.state.interval);
    }

    load = async () => {
        const { product, token } = this.props.match.params;
        this.setState({ data: await getLocationData(product, token) });
    }

    login = async () => {
        let { token, product } = this.props.match.params;
        this.setState({ token: token });
        this.setState({ product: product });
        let result = await getValidUserData(product, token)
        if (result.Message == "private") {
            this.setState({ valid: "private" });
        } else {

            this.setState({ valid: "public" })

        }
    }

    forecast = async () => {
        let { token, product } = this.props.match.params;
        let resultforecast = await getForecastData(product, token)
        let res = await fetch("https://forecasts.proaanalytics.com/GetForecast/" + token + "/" + product, {
        //let res = await fetch("/GetForecast/" + token + "/" + product, {
            headers: {
                "Content-Type": "application/json",
                "Username": getUser(),
                "Password": getPassword()
            }
        })
        let file = res.headers.get('content-disposition').split(';')[1];

        let forecastfile = file.split('=')[1];

        if (forecastfile) {

            this.setState({ forecastdata: resultforecast });
            this.setState({ filename: forecastfile });
        }
        else
            this.setState({ forecastdata: "Requested data is not available" });
        //}
    }


    // Put in gap below:{ <div id="link"><a href="https://forecasts.proaanalytics.com/GetReport/" + metadata.report.token + "/" metadata.report.report_id>View PDF report</a></div> }
    // have data.metadata in Display method - use this to get token and report_id
    // NB the metadata is at the start of the DispData json files. Report_id field needs to be added to fc_details

    render() {
        const { data } = this.state;
        const { valid } = this.state;
        const user = getUser();
        let { token, product } = this.state;

        if (user) {

            if (user == "proadmin") {
                this.props.match.params.override = true
            }

            return (
                <div>
                    <Header params={this.props.match.params} />
                    {
                        (data)
                            ? <Display data={this.state.data} params={this.props.match.params} dataforecast={this.state.forecastdata} filename={this.state.filename} />
                            : <Preloader style={{ marginTop: 300 }} />
                    }
                </div>
            );
        }
        else {
            if (valid == "private") {
                return (
                    <div>
                        <Header params={this.props.match.params} />
                        <Login token={token} product={product} />
                        <Footer />
                    </div>
                )
            }
            else {

                return (
                    <div>
                        <Header params={this.props.match.params} />
                        {
                            (data)
                                ? <Display data={this.state.data} params={this.props.match.params} dataforecast={this.state.forecastdata} filename={this.state.filename} />
                                : <Preloader style={{ marginTop: 300 }} />
                        }
                    </div>
                );
            }
        }
    }
}
const Display = ({ data, params, dataforecast, filename }) => {

    var do_not_show_fc_download = ['mondo', 'collinsville'];
    var fileDownload = require('js-file-download');

    if (filename) {
        if (filename.split('.')[1] == 'csv')
            var forecastdownload = dataforecast;

        else
            var forecastdownload = JSON.stringify(dataforecast);


        //const { metadata } = this.props;
    }
    if (data.metadata) {
        return (
            <React.Fragment>
                <div id="station-page" >
                    <CurrentStatus summary={data.table} metadata={data.metadata} />
                    <div class="container">
                        <div class="floatlink">
                            <Link to={'/locationhistory/' + params.token + '/' + params.product}>View historical data</Link>
                        </div>
                        {data.metadata.report == 1 ? <div class="floatlink">
                            <a href={"https://forecasts.proaanalytics.com/GetReport/" + params.token + "/" + params.product} target="_new">View PDF report</a>
                        </div> : null}
                        {/* <div class="floatlink">
        { arrContains(params.product,do_not_show_fc_download)==false? <a href={"https://forecasts.proaanalytics.com/Getforecast/" + params.token + "/" + params.product}>Download forecast</a>:null }
        </div> */}
                        <div class="floatlink">

                            <a style={{ cursor: 'pointer' }} onClick={() => fileDownload(forecastdownload, filename)}>
                                Download forecast</a>
                        </div>
                    </div>
                    {data.forecast.generation ? <GraphGeneration title="Generation" actual={data.actual} data={data.forecast.generation} times={data.forecast.time_stamp} /> : null}
                    {data.forecast.irradiance ? <GraphIrradiance title="Irradiance" actual={data.actual} forecast_prev={data.forecast_prev} data={data.forecast.irradiance} times={data.forecast.time_stamp} /> : null}
                    {data.forecast.temphum ? <GraphTempHum title="Temperature / Humidity" actual={data.actual} forecast_prev={data.forecast_prev} data={data.forecast.temphum} times={data.forecast.time_stamp} /> : null}
                    {data.forecast.wind ? <GraphWind title="Wind" actual={data.actual} forecast_prev={data.forecast_prev} data={data.forecast.wind} times={data.forecast.time_stamp} /> : null}
                    {data.forecast_prev[0].precipitation ? <GraphRain title="Historic Precipitation" actual={data.actual} forecast_prev={data.forecast_prev} /> : null}
                </div>
                <Footer relative={true} />
            </React.Fragment>


        )

    }

}

const Style = {
    Button: {
        display: "flex",
        flexDirection: "row",
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
        maxWidth: "600px"
    }
}
