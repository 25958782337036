import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar} from 'react-bootstrap';

import { disableBodyScroll } from 'body-scroll-lock';

import logo from '../assets/logo.png'
import './Header.css'

export default class Header extends React.Component {
    componentDidMount() {
        disableBodyScroll(document.getElementById('main-header'));
    }

    render = () => {
        return (
            <Navbar id="main-header" inverse collapseOnSelect staticTop={true} fluid={true} style={{marginBottom: 0, color: '#3c9ee9', backgroundColor: "#329ae9", borderColor: "#329ae9"}}>
                <Navbar.Header>
                    <Navbar.Brand>
                    <span style={{padding:0}}><img style={{position:'relative', display: 'inline-block', height: 50, marginTop: 0, marginLeft:25, marginRight:20 }} src={logo} alt="Proa Analytics Logo" /><span style={{color: '#ffffff', fontSize: '16px', padding: '10px', position: 'relative', top:'2.1px'}}>{this.props.displayName}</span></span>
                    </Navbar.Brand>
                    <Navbar.Toggle />
                </Navbar.Header>
               
            </Navbar>
        )
    }
}
