import React from 'react';

export default class Footer extends React.Component {
    render = () => {
        return (
            <footer style={{...styles.footer, position:(this.props.relative) ? 'relative' : 'absolute'}}>
                <div style={styles.container}>
                    <span style={styles.text}>© 2020 Proa Analytics | <a style={styles.link} href="/termsofservice" target="_blank">Terms of Service</a></span>
                </div>
            </footer>
        )
    }
}

const styles = {
    footer: {
        bottom:0,
        width: '100%',
        height: 20,
        backgroundColor: "#329ae9",
        display:'flex',
        justifyContent:'center',
        alignItems: 'center',
        color:"#FFF"
    },
    container: {
        width: 'auto',
        maxWidth: 680
    },
    text: {
        fontSize: 10,
        color:"#FFF",
    },
    link: {
        color:"#FFF",
        textDecoration: "underline"
    }
}