import olMap from 'ol/Map';
import olView from 'ol/View';
//import olProj from 'ol/proj';
import * as olProj from 'ol/proj';
import olOverlay from 'ol/Overlay';

//import { Component } from 'react';
import 'ol/ol.css';

import olFormatGeoJSON from 'ol/format/GeoJSON';
import olSourceVector from 'ol/source/Vector';
import olLayerVector from 'ol/layer/Vector';

import olStyleStyle from 'ol/style/Style';
import olStyleIcon from 'ol/style/Icon';
import olStyleReg from 'ol/style/RegularShape';
import olStyleStroke from 'ol/style/Stroke';


import osm from './layers/osm';
import layers from '../assets/layers.json';

import { getPopupData } from './api';
import rooftop from '../assets/rooftop.svg'
import network from '../assets/network.svg'
import solarfarm from '../assets/solarfarm.svg'
import { getUser, getPassword, removeUserSession } from '../container/login/common';

const factories = { osm };

const loadMap = (mapDiv, props, zoom) => {

    let map = new olMap({
        loadTilesWhileAnimating: true,
        target: mapDiv,
        layers: [],
        view: new olView({
            // center: olProj.fromLonLat([140, -30]),
            // zoom: 4.8,
            // minZoom: 4,
            // maxZoom: 19
            center: olProj.fromLonLat([zoom[0], zoom[1]]),
            zoom: zoom[2],
            minZoom: 4,
            maxZoom: 19
        })
    });
    addLayers(map);
    return map;
}


const registerEvent = (map, selectLocation, reset, popupDiv, props) => {
    const overlay = getOverlay(map, popupDiv);

    map.on('click', async e => {
        overlay.setPosition();
        const features = map.getFeaturesAtPixel(e.pixel);

        if (features) {
            const feature = features[0],
                coordinates = feature.getGeometry().getCoordinates();

            overlay.setPosition(coordinates);
            reset();

            try {
                const { Name, token } = Object.assign({}, feature['values_']);
                //let token = props.computedMatch.params['token']
                //let Name = props.computedMatch.params['product']
                selectLocation(await getPopupData(Name, token), token);
            } catch (e) { console.log(e); }
        }
    })
}

const getOverlay = (map, popupDiv) => {
    const container = document.getElementById(popupDiv),
        overlay = new olOverlay({
            id: popupDiv,
            element: container,
            autoPan: true,
            autoPanAnimation: {
                duration: 250
            }
        })

    map.addOverlay(overlay);
    return overlay;
}

const loadFeatures = (map, props, DisplayName) => {
    let product;
    let token;
    let dataPath
    if (props.match) {
        dataPath = "https://forecasts.proaanalytics.com/"
    }
    else {
        token = props.computedMatch.params['token']
        product = props.computedMatch.params['product']
        dataPath = "https://forecasts.proaanalytics.com/MapPoints/" + token + "/" + product
        //dataPath = "/MapPoints/" + token + '/' + product

    }
    fetch(dataPath, {
        headers: {
            "Content-Type": "application/json",
            "Username": getUser(),
            "Password": getPassword()
        }
    }
    )
        .then(response => response.json())
        .then(data => {
            const source = new olSourceVector({ wrapX: false }),
                features = new olFormatGeoJSON().readFeatures(data, { featureProjection: 'EPSG:3857' });
            setPointStyle(features)
            for (var j = 0; j < features.length; j++) {
                if (data.features[j].properties.type.includes("cluster")) {
                    setNetworkStyle(features[j]);
                }
                if (data.features[j].properties.type.includes("rooftop")) {
                    setRooftopStyle(features[j]);
                }
            }
            source.addFeatures(features);
            // remove layer if it exists before adding it back
            removeLayer(map, "points");
            map.addLayer(new olLayerVector({ source: source, name: "points" }));
        })
        .catch(e => { console.log(dataPath); console.log(e); })
}

var findClosest = function (x, arr) {
    var indexArr = arr.map(function (k) { return Math.abs(k - x) })
    var min = Math.min.apply(Math, indexArr)
    return indexArr.indexOf(min)
}

var calculateCentre = async (props) => {
    // also returns the display name
    let product;
    let token;
    let dataPath
    if (props.match) {
        dataPath = "https://forecasts.proaanalytics.com/"
    }
    else {
        token = props.computedMatch.params['token']
        product = props.computedMatch.params['product']
        dataPath = "https://forecasts.proaanalytics.com/MapPoints/" + token + "/" + product
        //dataPath = "/MapPoints/" + token + "/" + product
    }

    var dlons = [360, 180, 90, 45, 22.5, 11.25, 5.625, 2.813, 1.406, 0.703, 0.352, 0.176, 0.088, 0.044, 0.022, 0.011, 0.005, 0.003, 0.001, 0.0005, 0.00025];
    var zooms = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
    var ave_lon = 0;
    var ave_lat = 0;
    var max_lon = 100; var min_lon = 170
    var max_lat = -47; var min_lat = -6
    var idx; var idx_lon; var idx_lat;
    // var datap
    return fetch(dataPath,{
        headers: {
            "Content-Type": "application/json",
            "Username": getUser(),
            "Password": getPassword()
        }
    })
        .then(response => response.json())
        .then(data => {
            
            if (data.features) {
                for (var i = 0; i < data.features.length; i++) {
                    ave_lon += data.features[i].geometry.coordinates[0]
                    ave_lat += data.features[i].geometry.coordinates[1]
                    max_lon = Math.max(max_lon, data.features[i].geometry.coordinates[0])
                    min_lon = Math.min(min_lon, data.features[i].geometry.coordinates[0])
                    max_lat = Math.max(max_lat, data.features[i].geometry.coordinates[1])
                    min_lat = Math.min(min_lat, data.features[i].geometry.coordinates[1])
                }

                ave_lat = ave_lat / data.features.length;
                ave_lon = ave_lon / data.features.length;
                idx_lon = findClosest(max_lon - min_lon, dlons) + 1
                idx_lat = findClosest(max_lat - min_lat, dlons) + 1
                idx = Math.min(idx_lat, idx_lon)
                var zoom_data = [ave_lon, ave_lat, zooms[idx]]
                var DisplayName = data.features[0].properties.DisplayName
                if (data.features.length == 1) { zoom_data[2] = 4.8 }
                return [zoom_data, DisplayName]
            }
        })


}

const removeLayer = (map, name) => {
    let tempLayer = undefined;
    map.getLayers().forEach(l => {
        if (name === l.values_.name) {
            tempLayer = l;
        }
    })

    if (tempLayer) {
        map.removeLayer(tempLayer);
    }
}

const addLayers = (map) => {
    layers.basemap.forEach((layer) => {
        const type = layer.layer.type;
        if (type in factories) {
            map.addLayer(factories[type](layer.layer));
        }
    });
}

function setPointStyle(features, type) {
    features.forEach((feat) => {
        var style = [
            new olStyleStyle({
                image: new olStyleIcon(/** @type {olx.style.IconOptions} */({
                    anchor: [0.5, 0.65],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'fraction',
                    opacity: 1,
                    src: solarfarm

                }))
            }),
            new olStyleStyle({
                image: new olStyleReg({
                    stroke: new olStyleStroke({ color: [0, 0, 0, 0] }),
                    anchor: [1.9, 0.9],
                    points: 4,
                    radius: 40, // <--------- control its size
                    angle: Math.PI / 4
                })
            })
        ];

        feat.setStyle(style);
    });
}

function setNetworkStyle(feat) {

    var style = [
        new olStyleStyle({
            image: new olStyleIcon(/** @type {olx.style.IconOptions} */({
                anchor: [0.5, 0.65],
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                src: network

            }))
        }),
        new olStyleStyle({
            image: new olStyleReg({
                stroke: new olStyleStroke({ color: [0, 0, 0, 0] }),
                anchor: [1.9, 0.9],
                points: 4,
                radius: 40, // <--------- control its size
                angle: Math.PI / 4
            })
        })
    ];

    feat.setStyle(style);
}

function setRooftopStyle(feat) {

    var style = [
        new olStyleStyle({
            image: new olStyleIcon(/** @type {olx.style.IconOptions} */({
                anchor: [0.5, 0.65],
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                src: rooftop

            }))
        }),
        // add invisible rectangle over icon to make clickable box bigger
        new olStyleStyle({
            image: new olStyleReg({
                stroke: new olStyleStroke({ color: [0, 0, 0, 0] }),
                anchor: [1.9, 0.9],
                points: 4,
                radius: 40, // <--------- control its size
                angle: Math.PI / 4
            })
        })
    ];

    feat.setStyle(style);
}


export { loadMap, registerEvent, loadFeatures, calculateCentre }
