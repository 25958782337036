
  export default function reducer(state = { }, action) {
    switch (action.type) {
        case 'LOGIN_USER': {
            return {
                ...state,
                data: action.payload
            };
        }
        default: {
            return {...state};
        }
    }
}

export const Types = {
    LOGIN_USER: 'LOGIN_USER'
}
