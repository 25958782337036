import { Types as LocationTypes } from '../reducers/location';
import { Types as FeatureTypes } from '../reducers/feature';
import { Types as AuthUserTypes } from '../reducers/UserAuth';


const selectLocation = location => {
    return dispatch => {
        dispatch({
            type: LocationTypes.ON_SELECT,
            location
        });
    }
}

const dispatchUrls = (urls) => {
    return async dispatch => {
        dispatch({
            type: FeatureTypes.ON_SET_URLS,
            urls, urlsTimestamp: new Date().getTime()
        });
    }
}

const reset = () => {
    return dispatch => {
        dispatch({ type: LocationTypes.ON_RESET });
    }
}

const authUser = (data) => {
    return dispatch => {
        dispatch({
            type: AuthUserTypes.LOGIN_USER,
            data
        });
    }
}

export default reset;

export { reset, selectLocation, dispatchUrls, authUser };
