import React, { Component } from 'react';
import {Line} from 'react-chartjs-2';
import './Scatter.css';
import Preloader from '../Preloader'
import { Panel } from 'react-bootstrap'

export default class ScatterGraph extends Component {
    getDatasets() {
        const { data, times } = this.props;
        return {
            labels: times.map(x => x * 1000),
            datasets: [
            {
                fill: false,
                lineTension: 0.3,
                borderColor: 'green',
                backgroundColor: 'green',
                borderWidth: 1,
                pointRadius: 0,
                pointHitRadius: 10,
                data: data.dni_p50_W_m2, //getFilteredData(times, data, 'gen_p10_W_m2'),
                showLine: true,
                label: "Direct Normal Irradiance (DNI)"
            },
            {
                fill: false,
                lineTension: 0.3,
                borderColor: 'red',
                backgroundColor: 'red',
                borderWidth: 1,
                pointRadius: 0,
                pointHitRadius: 10,
                data: data.dhi_p50_W_m2, //getFilteredData(times, data, 'gen_p10_W_m2'),
                showLine: true,
                label: "Diffuse Horizontal Irradiance (DHI)"
            },    
            {
                fill: false,
                lineTension: 0.3,
                borderColor: '#505050',
                backgroundColor: '#505050',
                borderWidth: 1,
                pointRadius: 0,
                pointHitRadius: 10,
                data: data.ghi_p50_W_m2, //getFilteredData(times, data, 'gen_p10_W_m2'),
                showLine: true,
                label: "Global Horizontal Irradiance (GHI)"
            },
            {
                fill: false,
                lineTension: 0.3,
                borderColor: 'rgba(255,255,255,0)',
                borderWidth: 1,
                pointRadius: 0,
                pointHitRadius: 10,
                data: data.ghi_p10_W_m2, //getFilteredData(times, data, 'gen_p10_W_m2'),
                showLine: true,
                label: "p10"
            },
            {
                fill: 3,
                lineTension: 0.3,
                borderColor: 'rgba(255,255,255,0)',
                borderWidth: 1,
                pointRadius: 0,
                pointHitRadius: 10,
                data: data.ghi_p90_W_m2, //getFilteredData(times, data, 'gen_p10_W_m2'),
                showLine: true,
                label: "p90"
            }
            ]
        }
    }

    render() {
        const { title, data } = this.props;
        if (data) {
            const GraphDatasets = getGraphOptions();

            return (
                <div>
                   <Panel>
                        <Panel.Heading>
                            <Panel.Title componentClass="h2" style={{fontSize: '12px'}}>{title}</Panel.Title>
                        </Panel.Heading>
                        <Panel.Body>
                            <div id="mini-graph" style={{height: '400px'}}>
                            <Line data={this.getDatasets.bind(this)} options={GraphDatasets} datasetKeyProvider={()=>Math.random()}/>
                            </div>
                        </Panel.Body>
                    </Panel>
                </div>
            );
        } else {
            return <Preloader/>
        }
    }
}

const getGraphOptions = () => ({
    maintainAspectRatio: false,
    tooltips: {
        mode: 'label'
      },
    scales: {
        xAxes: [{
            type: 'time',
            time: {
                unit: 'hour',
                displayFormats: { hour: 'MMM DD - H a' },
                tooltipFormat: 'MMM DD H:mm a'
            },
            display: true,
            ticks: { fontSize: 12 }
        }],
        yAxes: [{
            ticks: {
                fontSize: 12,
                suggestedMin: 0
            },
            scaleLabel: {
                display: true,
                labelString: 'Watts/m²'
            }
        }]
    },
    legend: {
        display: true,
        labels: {
             filter: function(legendItem, data) {
                if(legendItem.text === "p10" || legendItem.text === "p90"){
                    return false
                }
                else {
                    return true;
                }
             },
        }
    
        
   }
})