import React, { Component } from 'react';
import {Line} from 'react-chartjs-2';
import './Scatter.css';
import Preloader from '../Preloader'
import { Panel } from 'react-bootstrap'
import Numeral from "numeral";
import { createDataset } from '../../lib/util';
 
export default class ScatterGraph extends Component {
    getDatasets() {

        const { actual, data, times } = this.props;
        const datasets = 
        {datasets: [{
            fill: false,
            lineTension: 0.3,
            borderColor: 'rgba(255, 124, 67, 0.7)',
            backgroundColor: 'rgba(255, 124, 67, 0.2)',
            borderDash: [2,2],
            borderWidth: 1,
            pointRadius: 0,
            pointHitRadius: 10,
            data: createDataset(times, data.gen_p10_W_m2), //getFilteredData(times, data, 'gen_p10_W_m2'),
            showLine: true,
            label: "10% POE",
            hidden: !data.gen_p10_W_m2
        },
        {
            fill: 1,
            lineTension: 0.3,
            borderColor: 'rgba(255, 124, 67, 1)',
            backgroundColor: 'rgba(255, 124, 67, 0.2)',
            borderDash: [10,2],
            borderWidth: 2,
            pointRadius: 0,
            pointHitRadius: 10,
            data: createDataset(times, data.gen_p50_W_m2), //getFilteredData(times, data, 'gen_p10_W_m2'),
            showLine: true,
            label: "Forecast",
            hidden: !data.gen_p50_W_m2
        },
        {
            fill: 0,
            lineTension: 0.3,
            borderColor: 'rgba(255, 124, 67, 0.7)',
            backgroundColor: 'rgba(255, 124, 67, 0.2)',
            borderDash: [10,5,2,5],
            borderWidth: 1,
            pointRadius: 0,
            pointHitRadius: 10,
            data: createDataset(times, data.gen_p90_W_m2), //getFilteredData(times, data, 'gen_p10_W_m2'),
            showLine: true,
            label: "90% POE",
            hidden: !data.gen_p90_W_m2
        },
        {
            fill: false,
            lineTension: 0.3,
            borderColor: '#505050',
            backgroundColor: '#505050',
            borderWidth: 2,
            pointRadius: 0,
            pointHitRadius: 10,
            data: createDataset(times, actual.generation.gen_W),
            showLine: true,
            label: "actual",
            hidden: !actual.generation.gen_W
        }   
        ]}
        var array = new Array();
        for(var i = 0; i < Object.keys(datasets.datasets).length; i++){
            if (!datasets.datasets[i].hidden) {
            array.push(datasets.datasets[i])
            }
        }
        return {datasets: array}
    }
    render() {
        const { title, data } = this.props;
        if (data) {
                const GraphDatasets = getGraphOptions(data.gen_p50_W_m2);
            return (
                <div>
                   <Panel>
                        <Panel.Heading>
                            <Panel.Title componentClass="h2">{title}</Panel.Title>
                        </Panel.Heading>
                        <Panel.Body>
                            <div id="mini-graph" style={{height: '400px'}}>
                            <Line data={this.getDatasets.bind(this)} options={GraphDatasets} datasetKeyProvider={()=>Math.random()}/>
                            </div>
                        </Panel.Body>
                    </Panel>
                </div>
            );
        } else {
            return <Preloader/>
        }
    }
}
 
const getGraphOptions = (vals) => ({
    maintainAspectRatio: false,
    tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
              label: function (tooltipItems, data) {
                if (tooltipItems.yLabel > 1e6) {
                    return Numeral(tooltipItems.yLabel/1e6).format('0.00')
                  } else {
                    return Numeral(tooltipItems.yLabel/1e3).format('0.00')
                  }
              }
          }
      },
    scales: {
        xAxes: [{
            type: 'time',
            time: {
                unit: 'hour',
                displayFormats: { hour: 'MMM DD, HH:mm' },
                tooltipFormat: 'MMM DD, HH:mm'
            },
            display: true,
            ticks: { fontSize: 12 }
        }],
        yAxes: [{
            ticks: {
                fontSize: 12,
                suggestedMin: 0,
                callback: function(value, index, values) {
                    if (Math.max.apply(Math, values) > 1e6) {
                        return Numeral(value/1e6).format('0.0')
                  } else if (Math.max.apply(Math, values) > 1e3) {
                        return Numeral(value/1e3).format('0.0')
                  }
                    else { //console.log(Math.max.apply(Math, values))
                        return Numeral(value).format('0.0') }
                }
            },
            scaleLabel: {
                display: true,
                labelString: getLabel(vals)
            }
        }
        ]
    },
    legend: {
        display: true
        // labels: {
        //     filter: function(item, chart) {
        //         return !item.hidden}
        // }
    }
})

var getLabel = function (values) {
    if (Math.max.apply(Math, values)>1e6) {
        return "Power (MW)"}
    else if (Math.max.apply(Math, values)>1e3) {
        return "Power (kW)"
    }
    else { return "Power (W)"}
}